import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { atHomeStore } from '@/store';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.content = null;
        this.items = [];
        this.subItem = '';
        this.codigoLivro = '';
        this.loading = true;
    }
    handleLanguage(item, book) {
        this.codigoLivro = item.livro_codigo;
        if (book.id === 1) {
            this.$router.push({
                name: 'pedagogico-minha-pratica',
                params: { id: item.turma_aluno_id },
            });
        }
        if (book.id === 2) {
            this.$router.push({
                name: 'pedagogico-notas',
                params: { id: item.turma_aluno_id },
            });
        }
        if (book.id === 3) {
            this.$router.push({
                name: 'at-home-details',
                params: { id: item.turma_aluno_id },
            });
            this.content = book.title + ' - ' + item.livro_nome;
        }
    }
    async addChips() {
        this.items.forEach((element) => {
            element.array = [
                {
                    id: 1,
                    title: 'Minha prática',
                    chips: [
                        { id: 1, name: 'Audição', icon: 'headphones' },
                        {
                            id: 2,
                            name: 'Glossário',
                            icon: 'list_alt',
                            chip: ['Palavras', 'Expressões', 'Verbos'],
                        },
                        { id: 3, name: 'Prática', icon: 'mic' },
                    ],
                },
                {
                    id: 2,
                    title: 'Notas',
                    chips: [
                        { id: 1, name: 'Boletim' },
                        { id: 2, name: 'Faltas' },
                        { id: 3, name: 'Reposição de aula' },
                    ],
                },
            ];
            if (element.local_aula_id == 2) {
                element.array.push({
                    id: 3,
                    title: 'KNN At home',
                    chips: [
                        { id: 1, name: 'Participar da aula' },
                        { id: 2, name: 'Enviar tarefa' },
                        { id: 3, name: 'Correção da tarefa' },
                    ],
                });
            }
        });
    }
    async mounted() {
        this.loading = true;
        this.items = await atHomeStore.getAtHomeLivrosAluno();
        await this.addChips();
        this.loading = false;
    }
};
List = __decorate([
    Component
], List);
export default List;
